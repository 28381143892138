import { render, staticRenderFns } from "./lvscBox13.vue?vue&type=template&id=d3da6a96&scoped=true"
import script from "./lvscBox13.vue?vue&type=script&lang=js"
export * from "./lvscBox13.vue?vue&type=script&lang=js"
import style0 from "./lvscBox13.vue?vue&type=style&index=0&id=d3da6a96&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d3da6a96",
  null
  
)

export default component.exports