<template>
    <div class="Awarp">
        <dashCard class="lvscBox13" :operateWidth="operateWidth" :isLoading="isLoading" :gridOption="gridOption" @changeScreen="changeScreen">
            <template slot="title">设备健康状态</template>
            <template slot="aside">
                <a-select v-model="searchObj['ITEM']" style="width: 220px" @change="handleChange" :getPopupContainer="triggerNode => triggerNode.parentNode">
                    <a-select-option v-for="(item,key) in itemList" :key="key" :value="item.code">
                        {{ item.name }}
                    </a-select-option>
                </a-select>
            </template>
            <div class="card-content1">
                    <div class="product-pre">
                        <div>
                            <img :src="detailInfo.productSrc"/>
                        </div>
                    </div>
                        <a-descriptions bordered>
                            <a-descriptions-item v-for="(item,idx) in columns" :key="idx" :label="item.title" :span="3">
                                <span v-if="!!item.value1">{{ detailInfo[item.value1] }}</span>
                                <span v-if="!!item.value2"> {{ detailInfo[item.value2] }}</span>
                            </a-descriptions-item>
                        </a-descriptions>
            </div>
            <div class="card-content2">

                <div class="aside-card-content">
                    <div class="aside-card-content2">
                        <chartBoard ref="chart1" :option="chartOption1"/>
                    </div>
                    <div class="aside-card-content3">
                        <div class="content-li-line-box">
                            <span/>
                            <span/>
                            <span/>
                        </div>
                        <div class="content-li-line-box-label">
                            <span>偏低</span>
                            <span>一般</span>
                            <span>良好</span>
                        </div>
                    </div>
                </div>

                
                <div class="product-info">
                    <div class="product-info-item">
                        <div class="product-info-item-top">
                            <span>运行电压</span>
                            <span>380V</span>
                        </div>
                        <div class="product-info-item-progress">
                            <span style="width:95%"></span>
                        </div>
                        <div class="product-info-item-bottom">
                            <span>0</span>
                            <span>400V</span>
                        </div>
                    </div>

                    <div class="product-info-item">
                        <div class="product-info-item-top">
                            <span>运行电流</span>
                            <span>200kW</span>
                        </div>
                        <div class="product-info-item-progress">
                            <span style="width:50%"></span>
                        </div>
                        <div class="product-info-item-bottom">
                            <span>0</span>
                            <span>400kW</span>
                        </div>
                    </div>

                    <div class="product-info-item">
                        <div class="product-info-item-top">
                            <span>功率</span>
                            <span>80℃</span>
                        </div>
                        <div class="product-info-item-progress">
                            <span style="width:88.9%"></span>
                        </div>
                        <div class="product-info-item-bottom">
                            <span>0</span>
                            <span>90℃</span>
                        </div>
                    </div>

                </div>
            </div>
        </dashCard>
        <a-modal v-if="!isModal" class="Amodal" v-model="option.visible" :closable="false" :footer="null" :keyboard="false" width="100%" :dialog-style="{ top: '0px' }">
            <div style="height:calc(100vh)">
                <lvscBox13  :option="option" :isModal="true" :orginSearchObj="searchObj"/>
            </div>
        </a-modal>
    </div>
    </template>
    <script>
    import Img001 from "@/assets/imgs/add/product1.png"
    export default {
        name: 'lvscBox13',
        components: {
            dashCard: () => import('@/component/dashCard.vue'),
            chartBoard: () => import('@/component/chartBoard.vue'),
        },
        props: {
            gridOption: {
                type: Object,
                default: function() {
                    return { w: 6, h: 6 }
                }
            },
            option: {
                type: Object,
                default: function() {
                    return {
                        visible: false
                    }
                }
            },
            isModal: {
                type: Boolean,
                default: function() {
                    return false
                }
            },
            orginSearchObj: {
                type: Object,
                default: function() {
                    return {}
                } 
            }
        },
        watch: {
            gridOption: {
                handler: function(newVal,oldVal) {
                    this.$nextTick(_=>{
                        this.getOperateWidth()
                        var dom = this.$refs['chart1']
                        if(!!dom) {
                            dom.handleWindowResize()
                        }
                    })
                },
                deep: true,
                immediate: true
            },
            "option.visible": {//全屏化时执行
                handler: function(newVal,oldVal) {
                    if(newVal) {
                        if(this.isModal) {
                            this.searchObj = this.orginSearchObj
                            this.getData()
                        }
                    }
                },
                deep: true,
                immediate: true
            },
        },
        data() {
            return {
                operateWidth: 64, //操作列宽度
                isLoading: false,//数据加载中

                itemList: [
                {
                    code: '1AT1_抽屉柜',
                    name:'1AT1_抽屉柜'
                },
                {
                    code: '1AT2_抽屉柜',
                    name:'1AT2_抽屉柜'
                },
                {
                    code: '1AT3_抽屉柜',
                    name:'1AT3_抽屉柜'
                }
            ],

            columns: [
                { title: '设备名称', value1: 'value1'},
                { title: '型号', value1: 'value2',value2: 'value3'},
                { title: '上次维护时间', value1: 'value4',value2: 'value5'},
                { title: '额定电压', value1: 'value6',value2: 'value7',},
                { title: '额定电流', value1: 'value8',value2: 'value9'},
                { title: '额定频率', value1: 'value10',value2: 'value11'},
            ],
            searchObj: {
                ITEM: '1AT1_抽屉柜',//选中的项目
            },
            chartOption1: {},
            detailInfo: {
                chart1: {

                }
            },
            colorList: ["#FF7300","#DBDBDB","#F03040","#D21423"],
            }
        },
        computed: {},
        methods: {
            // 全屏切换
            changeScreen() {
                this.option.visible = !this.isModal
                if(!this.option.visible) {
                    this.getData()
                }
            },
            // 右侧操作列宽度
            getOperateWidth () {
                if(!this.$el.querySelector) {
                    return
                }
                var dom = this.$el.querySelector(".card-head-operate")
                if(!!dom) {
                    var width = dom.clientWidth
                    this.operateWidth = width > 0 ? width + 12 : width  
                }
            },
            // 数字转为千位分隔符表示
            toThousandsSeparator: function(value) {
                if(!value) return 0
                // 获取整数部分
                const intPart = Math.trunc(value)
                // 整数部分处理，增加,
                const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
                // 预定义小数部分
                let floatPart = ''
                // 将数值截取为小数部分和整数部分
                const valueArray = value.toString().split('.')
                if (valueArray.length === 2) { // 有小数部分
                    floatPart = valueArray[1].toString() // 取得小数部分
                    return intPartFormat + '.' + floatPart
                }
                return intPartFormat + floatPart
            },

        // 切换项目
        handleChange(value) {
            this.getData()
        },
        onChange() {
            this.getData()
        },
        // 获取数据
        getData() {
            this.isLoading = true
            var chart1 = {
                min: 0,
                max: 100,
                percent: 61.00
            }
            this.detailInfo = {
                value1:"抽屉柜",
                value2:"ABB_3P",
                value3:"63A-400A",
                value4:"128",
                value5:"h",
                value6:"400",
                value7:"V",
                value8:"1000",
                value9:"A",


                value10:"2780",
                value11:"kWh",

                productSrc: Img001,
                chart1: chart1
            }
            this.initEchart()
            this.isLoading = false
        },
		initEchart() {     
            var {percent} = this.detailInfo.chart1

            var option = {
                animation:false,
                layoutAnimation:false,
                color: ["#DBDBDB","#FF7300"],
                tooltip: {
                    trigger: 'item'
                },
                angleAxis: {
                    axisLine: {
                        show: false,
                    },
                    axisLabel: {
                        show: false,
                    },
                    splitLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    min: 0,
                    max: 6.666,
                    // boundaryGap: ['0', '10'],
                    startAngle: 225,
                },
                radiusAxis: {
                    type: 'category',
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        show: false,
                    },
                    data: ['a', 'b', 'c'],
                    z: 10,
                },
                polar: [{
                    // center: ['50%', '50%'], //中心点位置
                    // radius: '10%' //图形大小
                }],
                series: [
                    // 背景
                    {
                        animation: false,
                        name: '健康评分',
                        type: 'gauge',
                        radius:'80%',
                        center: ['50%', '60%'],
                        splitNumber: 12,
                        // 展示当前进度
                        progress: {
                            show: true,
                            width: 8,
                            itemStyle: {
                                color: '#DBDBDB',
                                fontFamily: 'ABBvoice_WCNSG_Rg',
                            },
                        },
                        // 仪表盘指针
                        pointer: {
                            show: false,
                        },
                        // 仪表盘轴线相关配置
                        axisLine: {
                            show: false,
                        },
                        // 刻度样式
                        axisTick: {
                            show: false,
                        },
                        // 分隔线样式
                        splitLine: {
                            show: false,
                        },
                        // 刻度标签
                        axisLabel: {
                            show: false,
                        },
                        // 表盘中指针的固定点
                        anchor: {
                            show: false,
                        },
                        detail: {
                            show: false,
                        },
                        data: [
                            {
                                value: 100,
                            },
                        ],
                    },
                    // 内容
                    {
                        name: '',
                        type: 'gauge',
                        radius:'80%',
                        center: ['50%', '60%'],
                        min: 0,
                        max: 100,
                        zlevel: 10,
                        splitNumber: 12,
                        // 展示当前进度
                        progress: {
                            show: true,
                            width: 8,
                            itemStyle: {
                                color: '#FF7300',
                                fontFamily: 'ABBvoice_WCNSG_Rg',

                            },
                        },
                        // 仪表盘指针
                        pointer: {
                            show: false,
                        },
                        // 仪表盘轴线相关配置
                        axisLine: {
                            show: false,
                        },
                        // 刻度样式
                        axisTick: {
                            show: false,
                        },
                        // 分隔线样式
                        splitLine: {
                            show: false,
                        },
                        splitNumber: 1,
                        // 刻度标签
                        axisLabel: {
                            show: true,
                            padding: [0, 10, 0, 10],
                            distance: -30,
                        },
                        // 表盘中指针的固定点
                        anchor: {
                            show: false,
                        },
                        detail: {
                            borderRadius: 8,
                            offsetCenter: ['0%', '-15%'],
                            fontSize: 30,
                            fontWeight: 'bolder',
                            formatter: '{value}',
                            color: 'rgba(0,0,0,.65)',
                            fontFamily: 'ABBvoice_WCNSG_Rg',
                        },
                        data: [
                            {
                                value: percent,
                                name: '健康评分'
                            },
                        ],
                    },

                ],
            };
            
            this.updateChart('chart1', 'chartOption1', option)
        },
        updateChart: function (refName, optionName, option = {}) {
            /* 渲染echart图 */
            if (!optionName) return
            this[optionName] = option
            setTimeout(() => {
                this.$refs[refName].updateChartView()
            }, 500)
        },

            
        },
        mounted() {
            this.getOperateWidth()
            if(!this.option.visible) {
                this.getData()
            }
        }
    }
    </script>
    
<style lang="less" scoped>
.lvscBox13 {
    /deep/.card-content {
        flex-direction: row !important;
    }
    .card-content1 {
            display: flex;
            flex-direction: column;
            .product-pre {
                width: 346px;
                height: 336px;
                display: flex;
                justify-content: center;
                align-items: center;
            }


        }
        .card-content2 {
            flex: 1;
            display: flex;
            flex-direction: column;
            .aside-card-content {
                flex: 1;
                height: 100%;
                display: flex;
                flex-direction: column;
                .aside-card-content2 {
                    flex: 1;
                    height: 100%;
                    div{
                        width: 100%;
                        height: 100%;
                    }
                }
                .aside-card-content3 {
                    height: 93px;
                    width: 100%;
                    box-sizing: border-box;
                    padding-top: 50px;
                    display: flex;
                    flex-direction: column;
                    padding: 0;
                    font {
                        font-size: 16px;
                        font-weight: 500;
                        color: #1f1f1f;
                        line-height: 20px;
                    }
                    span {
                        display: flex;
                        justify-content: space-between;


                    }
                    .content-li-line-box {
                        width: 100%;
                        height: 8px;
                        display: flex;
                        background: #dbdbdb;
                        margin: 12px 0 9px;
                        span {
                            flex: 1;

                            height: inherit;
                            display: inline-block;
                            &:nth-child(1) {
                                background: #F1404F;
                            }
                            &:nth-child(2) {
                                background: #FF7300;
                                border-left: 2px solid #000000;
                                border-right: 2px solid #000000;
                            }
                            &:nth-child(3) {
                                background: #25B131;
                            }
                        }
                    }
                    .content-li-line-box-label {
                        width: 100%;
                        display: flex;
                        span {
                            flex: 1;
                            text-align: center;
                            height: inherit;
                            display: inline-block;
                        }
                    }


                }
            }

            .product-info {
                flex: 1;
                .product-info-item {
                    margin-bottom: 24px;
                   .product-info-item-top {
                    display: flex;
                    justify-content: space-between;

                    font-size: 16px;
                    font-weight: 500;
                    color: #1f1f1f;
                    line-height: 20px;
                   } 
                   .product-info-item-progress {
                    width: 100%;
                    height: 8px;
                    background: #dbdbdb;
                    margin-top: 14px;
                    margin-bottom: 9px;
                    span {
                        display: block;
                        height: inherit;
                        width: 0%;
                        background: #51A34B;
                    }
                   }
                   .product-info-item-bottom {
                    display: flex;
                    justify-content: space-between;

                    font-size: 14px;
                    font-weight: 400;
                    color: #696969;
                    line-height: 16px;
                   }
                }
                
            }
        }
        /deep/.ant-descriptions-bordered 
        {
            .ant-descriptions-view {
                border: 0;
            }
            .ant-descriptions-item-label {
                border: 0;
            }
            .ant-descriptions-row {
                border: 0;
            }
            .ant-descriptions-item-label {
                background: transparent;
                // width: 362px;
            }
            .ant-descriptions-item-content {
                background: transparent;
                // background: #F5F5F5;
                display: flex;
                span {
                    margin-right: 10px;
                }
            }
        }
}
</style>